import firebase from "firebase";
import { db } from "../firebase/firebase";
import _, { filter } from "lodash";
export const START = "START";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const FETCH_PACKAGES_LIST = "FETCH_PACKAGES_LIST";
export const FETCH_PACKAGE_SUBSCRIPTIONS = "FETCH_PACKAGE_SUBSCRIPTIONS";
export const FETCH_PACKAGES_BY_INSTITUTE_ID = "FETCH_PACKAGES_BY_INSTITUTE_ID";

export const GET_ONE_PACKAGE = "GET_ONE_PACKAGE";
export const SELECT_INSTITUTE = "SELECT_INSTITUTE";
export const GET_SET_BY_INSTITUTE = "GET_SET_BY_INSTITUTE";
export const FETCH_PACKAGE_SUBSCRIPTIONS_BY_USERID =
  "FETCH_PACKAGE_SUBSCRIPTIONS_BY_USERID";
export const FETCH_PACKAGE_SUBSCRIPTIONS_BY_ID =
  "FETCH_PACKAGE_SUBSCRIPTIONS_BY_ID";

const start = () => {
  return {
    type: START,
  };
};
const success = (payment) => {
  return {
    type: SUCCESS,
    payload: payment,
  };
};
const error = () => {
  return {
    type: ERROR,
  };
};

export const fetchPackages = (packages) => {
  return {
    type: FETCH_PACKAGES_LIST,
    payload: packages,
  };
};

export const fetchPackagesByInstituteId = ({ packages, instituteId }) => {
  return {
    type: FETCH_PACKAGES_BY_INSTITUTE_ID,
    payload: { instituteId, packages },
  };
};

export const fetchPackagesSubscriptions = (packageSubscriptions) => {
  return {
    type: FETCH_PACKAGE_SUBSCRIPTIONS,
    payload: packageSubscriptions,
  };
};

export const fetchPackagesByInstitute = (packages) => {
  return {
    type: FETCH_PACKAGE_SUBSCRIPTIONS,
    payload: packages,
  };
};

export const fetchPackagesSubscriptionsByUserId = (
  packageSubscriptionsByUserId
) => {
  return {
    type: FETCH_PACKAGE_SUBSCRIPTIONS_BY_USERID,
    payload: packageSubscriptionsByUserId,
  };
};

export const fetchPackagesSubscriptionsById = (
  packageSubscriptionsById,
  id
) => {
  return {
    type: FETCH_PACKAGE_SUBSCRIPTIONS_BY_ID,
    payload: { id, packageSubscriptionsById },
  };
};

export const getOnePackage = (packageDetail) => {
  return {
    type: GET_ONE_PACKAGE,
    payload: packageDetail,
  };
};

export const selectInstitute = (institute) => {
  return {
    type: SELECT_INSTITUTE,
    payload: institute,
  };
};

export const setSets = (sets) => {
  return {
    type: GET_SET_BY_INSTITUTE,
    payload: sets,
  };
};

const calculatePrice = (sets, selectedSets) => {
  let total = 0;
  selectedSets.forEach((set) => {
    const setFound = sets.find((s) => s.id === set);
    if (setFound && setFound.type === "Paid") {
      total = total + (setFound.price || 0);
    }
  });
  return total || 0;
};

export const addPackage = (packageDetail) => async (dispatch, getState) => {
  dispatch(start());
  try {
    const allSets = getState().setPackaging.instituteSets;
    const data = {
      ...packageDetail,
      sets: _.get(packageDetail, "sets", []).map((set) => set.value),
      discount_percent: _.parseInt(_.get(packageDetail, "discount_percent", 0)),
      sets_meta: _.get(packageDetail, "sets", []),
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    };

    const packageDoc = db.collection("packages").doc();
    await packageDoc.set({ ...data, id: packageDoc.id });
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};
export const deletePackage = (packageId) => async (dispatch) => {
  dispatch(start());
  try {
    await db.collection("packages").doc(packageId).delete();
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};
export const editPackage = (packageId, packageDetail) => async (dispatch) => {
  dispatch(start());
  try {
    const dataToUpdate = {
      ...packageDetail,
      sets: _.get(packageDetail, "sets", []).map((set) => set.value),
      sets_meta: _.get(packageDetail, "sets", []),
      discount_percent: _.parseInt(_.get(packageDetail, "discount_percent", 0)),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    };
    await db.collection("packages").doc(packageId).update(dataToUpdate);
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const getAllPackages = (packageParam) => async (dispatch) => {
  const {
    instituteId,
    storeLastIndexRefOfDoc,
    limit,
    setStoreLastIndexRefOfDoc,
    setStopFetching,
    previousPackage = {},
    set,
    role,
  } = packageParam ?? {};

  if (instituteId === undefined && role !== "institute") {
    dispatch(start());
  }

  try {
    let query = db.collection("packages").orderBy("created_at", "desc");

    if (limit) {
      query = query.limit(limit);
    }
    if (instituteId) {
      query = query.where("institute.value", "==", instituteId);
    }

    if (set !== "all") {
      query = query.where("package_type", "==", set);
    }

    if (storeLastIndexRefOfDoc) {
      query = query.startAfter(storeLastIndexRefOfDoc);
    }

    query.onSnapshot(({ docs }) => {
      const result = docs.map((doc) => ({ ...doc.data(), id: doc.id }));

      if (setStopFetching) {
        setStopFetching(result.length === 0);
      }

      if (setStoreLastIndexRefOfDoc) {
        const lastVisibleRef = docs[docs.length - 1];
        setStoreLastIndexRefOfDoc(lastVisibleRef);
      }

      let packagesWithSet = {
        all: [],
      };
      if (set === "all") {
        packagesWithSet = {
          ...previousPackage,
          all: [...(previousPackage?.["all"] ?? []), ...result],
        };
      } else {
        packagesWithSet = {
          ...previousPackage,
          [set]: [...(previousPackage?.[set] ?? []), ...result],
        };
      }

      if (instituteId && role !== "institute") {
        dispatch(
          fetchPackagesByInstituteId({
            instituteId,
            packages: packagesWithSet.all,
          })
        );
      } else {
        dispatch(fetchPackages(packagesWithSet));
      }

      dispatch(success());
    });
  } catch (err) {
    dispatch(error());
  }
};

export const getOnePackageDetail = (packageId) => async (dispatch) => {
  dispatch(start());
  try {
    const packageDoc = await db.collection("packages").doc(packageId).get();
    dispatch(getOnePackage({ ...packageDoc.data(), id: packageId }));
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const getFilteredSets = (filters) => async (dispatch) => {
  dispatch(start());
  try {
    if (!filters.packageType) {
      return;
    }
    let dbRef = db.collection(filters.packageType.toLowerCase());

    if (filters.packageType === "PRACTICE_SETS" && filters.modelType) {
      dbRef = dbRef.where("level", "==", filters.modelType);
    } else if (filters.packageType === "MODEL_SETS" && filters.modelType) {
      dbRef = dbRef.where("model_type", "==", filters.modelType);
    }
    const { docs } = await dbRef.get();
    let result = docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    if (filters.packageType === "MODEL_SETS" && filters.instituteId) {
      result = result.filter(
        (res) => res.institute.value === filters.instituteId
      );
    }

    dispatch(setSets(result));
    dispatch(success());
  } catch (err) {
    dispatch(error());
  }
};

export const changePackageStatus = (status, packageId) => (dispatch) => {
  dispatch(start());
  db.collection(`packages`)
    .doc(packageId)
    .update({
      status: status,
    })
    .then((data) => {
      dispatch(success());
    })
    .catch((err) => dispatch(error()));
};

export const deletPackage = (packageId) => (dispatch) => {
  dispatch(start());
  db.collection(`packages`)
    .doc(packageId)
    .delete()
    .then((snapshot) => {
      dispatch(success());
    })
    .catch((err) => {
      dispatch(error());
    });
};

export const addSubscriptionFromSetPackage = async ({ subscription }) => {
  const { emails, foundUsersIdEmail, ...remainingSubscription } = subscription;

  try {
    const createSubscription = subscription.foundUsersIdEmail.map((email) => {
      return batchFn({ ...remainingSubscription, userId: email.id });
    });

    await Promise.all(createSubscription);
  } catch (error) {
    console.error({ error });
  }
};

async function batchFn(subscription) {
  try {
    const batch = db.batch();
    const subscriptionByUser = await db
      .collection("users")
      .doc(subscription.userId)
      .collection("package_subscriptions")
      .where("packageId", "==", subscription.packageId)
      .get();

    const hasUserSubscribed = subscriptionByUser.docs.map((subscription) =>
      subscription.data()
    );
    if (hasUserSubscribed.length === 0) {
      const reference = db
        .collection("users")
        .doc(subscription.userId)
        .collection("package_subscriptions")
        .doc();

      subscription.id = reference.id;
      batch.set(reference, subscription);

      //  put package subscription id into the subscription_ids of packages collection

      const packageRef = db.collection("packages").doc(subscription.packageId);

      batch.update(packageRef, {
        subscription_ids: firebase.firestore.FieldValue.arrayUnion(
          reference.id
        ),
      });

      await batch.commit();
    }
  } catch (error) {
    console.error(error);
  }
}
export const updatePackageSubscriptions = async (remainingSubscription) => {
  const batch = db.batch();
  const { id, userId } = remainingSubscription;
  try {
    const packageRef = db
      .collection("users")
      .doc(userId)
      .collection("package_subscriptions")
      .doc(id);

    batch.update(packageRef, {
      ...remainingSubscription,
      renewedAt: firebase.firestore.FieldValue.arrayUnion(Date.now()),
      updatedAt: Date.now(),
    });

    await batch.commit();
  } catch (error) {}
};

export const getAllPackageSubscriptions = () => async (dispatch) => {
  dispatch(start());
  try {
    const packageSubscriptions = await db
      .collectionGroup("package_subscriptions")
      .get();

    const response = packageSubscriptions.docs.map((packageSubscription) => ({
      ...packageSubscription.data(),
      id: packageSubscription.id,
    }));
    dispatch(fetchPackagesSubscriptions(response));
    dispatch(success());
  } catch (error) {
    dispatch(error());
  }
};

export const getPackageSubscriptionsByUserId = (userId) => async (dispatch) => {
  dispatch(start());
  try {
    const packageSubscriptions = await db
      .collection("users")
      .doc(userId)
      .collection("package_subscriptions")
      .get();

    const response = packageSubscriptions.docs.map((packageSubscription) =>
      packageSubscription.data()
    );
    dispatch(fetchPackagesSubscriptionsByUserId(response));
    dispatch(success());
  } catch (error) {
    dispatch(error());
  }
};

export const getPackageSubscriptionsByPackageId =
  (packageSubscriptionParam) => async (dispatch) => {
    const {
      packageId,
      storeLastIndexRefOfDoc,
      limit,
      setStoreLastIndexRefOfDoc,
      page,
      oldPackageSubscriptionWithPage = [],
      setStopFetching,
    } = packageSubscriptionParam;
    dispatch(start());
    try {
      let packageSubscriptionsQuery = db
        .collectionGroup("package_subscriptions")
        .where("packageId", "==", packageId);

      // TODO: (PRASIDHA) UNCOMMENT THIS CODE WHEN WE NEED TO IMPLEMENT PAGINATION

      // if (limit) {
      //   packageSubscriptionsQuery = packageSubscriptionsQuery.limit(limit);
      // }

      if (storeLastIndexRefOfDoc) {
        packageSubscriptionsQuery = packageSubscriptionsQuery.startAfter(
          storeLastIndexRefOfDoc
        );
      }

      const response = await packageSubscriptionsQuery.get();

      if (setStoreLastIndexRefOfDoc) {
        const lastVisible = response.docs[response.docs.length - 1];
        setStoreLastIndexRefOfDoc(lastVisible);
      }

      const packageSubscriptions = response.docs.map((r) => {
        return { ...r.data(), id: r.ref.id };
      });

      setStopFetching(packageSubscriptions.length === 0);

      dispatch(
        fetchPackagesSubscriptionsById(
          [...oldPackageSubscriptionWithPage, ...packageSubscriptions],
          packageId
        )
      );
      dispatch(success());
    } catch (error) {
      dispatch(error());
    }
  };

export const deletePackageSubscription = async (req) => {
  const { userId, packageSubscriptionId, packageId } = req;

  const batch = db.batch();
  try {
    const packageSubscriptionref = db
      .collection("users")
      .doc(userId)
      .collection("package_subscriptions")
      .doc(packageSubscriptionId);

    batch.delete(packageSubscriptionref);

    const packageRef = db.collection("packages").doc(packageId);

    batch.set(
      packageRef,
      {
        subscription_ids: firebase.firestore.FieldValue.arrayRemove(
          packageSubscriptionId
        ),
      },
      { merge: true }
    );

    batch.commit();
  } catch (error) {
    console.error(error);
  }
};

export async function getPackageSubscriptionsByPackageIdQuery(packageId) {
  if (!packageId) return [];

  const packageSubscriptionsQuery = db
    .collectionGroup("package_subscriptions")
    .where("packageId", "==", packageId);

  const response = await packageSubscriptionsQuery.get();
  return response.docs.map((res_doc) => res_doc.data());
}

export async function getPackageSubscriptionsByIdQuery(id) {
  if (!id) return null;

  try {
    const packageSubscriptionsQuery = db
      .collectionGroup("package_subscriptions")
      .where("id", "==", id);
    const response = await packageSubscriptionsQuery.get();
    const subscription = response.docs.map((res_doc) => res_doc.data());
    return subscription[0];
  } catch (error) {
    console.error({ error });
  }
}

export async function getPackageById(packageId) {
  if (!packageId) return null;

  const packageDoc = await db.collection("packages").doc(packageId).get();
  return { ...packageDoc.data(), id: packageId };
}
